import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/operators';
import { Http, Headers } from '@angular/http';

import 'rxjs/add/operator/map'; 

@Injectable()
export class UserService {


  // readonly rootUrl = 'http://localhost:5000/';
  // url: string = 'http://localhost:5000/';



  readonly rootUrl = 'https://api.sibawayharabic.com/';
  url: string = 'https://api.sibawayharabic.com/';


constructor(private Http: Http) { }

  login(item) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let credentials = { email: item.email, password: item.password  };
    console.log(item)
    return this.Http.post(this.url + 'api/auth', JSON.stringify(credentials), { headers: headers });
  }
  


  checktoken(token,id) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let credentials = { token, id };
    console.log(token, id)
    return this.Http.post(this.url + 'api/checktoken', JSON.stringify(credentials), { headers: headers });
  }

  
  fetchQuestions(item) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let credentials = { token: item};
    console.log(item)
    return this.Http.post(this.url + 'api/fulllistquestion', JSON.stringify(credentials), { headers: headers });
  }
  fetchUsers(item) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let credentials = { token: item };
    console.log(item)
    return this.Http.post(this.url + 'api/listuser', JSON.stringify(credentials), { headers: headers });
  }

  
  Updateq(token, id, answer_note,
question_title,
question_pre_title,
question_title_extra,
active,
active_multiple_chapter,
premium) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let credentials = {
      token, id, answer_note,
      question_title,
      question_pre_title,
      question_title_extra,
      active,
      active_multiple_chapter,
      premium};
    console.log(token, id, active)
    return this.Http.post(this.url + 'api/updateq', JSON.stringify(credentials), { headers: headers });
  }

  deleteq(token, id, answer_note,
    question_title,
    question_pre_title,
    question_title_extra,
    active,
    active_multiple_chapter,
    premium) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let credentials = {
      token, id, answer_note,
      question_title,
      question_pre_title,
      question_title_extra,
      active,
      active_multiple_chapter,
      premium
    };
    console.log(token, id, active)
    return this.Http.post(this.url + 'api/deleteq', JSON.stringify(credentials), { headers: headers });
  }

  
  Block(token, id, active) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let credentials = { token, id, active };
    console.log(token, id, active)
    return this.Http.post(this.url + 'api/blockuser', JSON.stringify(credentials), { headers: headers });
  }

  uploadfile(token, file , type) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let credentials = {
   token, file, type
      
    };
    return this.Http.post(this.url + 'api/s3push', JSON.stringify(credentials), { headers: headers });
  } 


  addq(token, wrong_answer, correct_answer, answer_note, question, question_title, question_title_extra, question_voice, question_img, answer_voice, question_pre_title, active, premium, active_multiple_chapter, chapter, type, answer_img){
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let credentials = {    token, wrong_answer, correct_answer, answer_note, question, question_title, question_title_extra, question_voice, question_img, answer_voice, question_pre_title, active, premium, active_multiple_chapter, chapter, type, answer_img};
  
    return this.Http.post(this.url + 'api/addquestion', JSON.stringify(credentials), { headers: headers });
  }













listclinic(){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {};
  return this.Http.post(this.url + 'api/findallclinic', JSON.stringify(credentials), {headers: headers});
}
findallappointment(){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {};
  return this.Http.post(this.url + 'api/findallappointment', JSON.stringify(credentials), {headers: headers});
}
findalluser(){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {};
  return this.Http.post(this.url + 'api/findalluser', JSON.stringify(credentials), {headers: headers});
}
listservices(){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {};
  return this.Http.post(this.url + 'api/findallservice', JSON.stringify(credentials), {headers: headers});
}
listcategory(){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {};
  return this.Http.post(this.url + 'api/findallcategory', JSON.stringify(credentials), {headers: headers});
}
view_fixed_values(){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {};
  return this.Http.post(this.url + 'api/view_fixed_values', JSON.stringify(credentials), {headers: headers});
}
findalloffers(){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {};
  return this.Http.post(this.url + 'api/findalloffersadmin', JSON.stringify(credentials), {headers: headers});
}
findallpromotion(){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {};
  return this.Http.post(this.url + 'api/findallpromotion', JSON.stringify(credentials), {headers: headers});
}
saveChangesclinic(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/updateclinic', JSON.stringify(credentials), {headers: headers});
}
verifyWebToken(item){
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
   let credentials = {item};
   return this.Http.post(this.url + 'api/verifyWebToken', JSON.stringify(credentials), {headers: headers});
}

// addphoto(id, image) {
//   let headers = new Headers();
//     headers.append('Content-Type', 'application/json');
//     let credentials = {       token :   AppComponent.token   ,  
//       id, image
//     };
//     return this.Http.post(this.url + 'api/addphoto', JSON.stringify(credentials), {headers: headers});
// } 
// changecover(id, image) {
//   let headers = new Headers();
//     headers.append('Content-Type', 'application/json');
//     let credentials = {       token :   AppComponent.token   ,  
//       id, image
//     };
//     return this.Http.post(this.url + 'api/changecover', JSON.stringify(credentials), {headers: headers});
// } 

addclinic(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/addclinic', JSON.stringify(credentials), {headers: headers});
}
newpass(token ,oPassword ,nPassword){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {token ,oPassword ,nPassword};
  return this.Http.post(this.url + 'api/newpass', JSON.stringify(credentials), {headers: headers});
}
myinvoce(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/myinvoce', JSON.stringify(credentials), {headers: headers});
}
joinusall(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/joinusall', JSON.stringify(credentials), {headers: headers});
}
allinvoce(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/allinvoce', JSON.stringify(credentials), {headers: headers});
}
payment_gateway(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/payment_gateway', JSON.stringify(credentials), {headers: headers});
}
payment_gateway_offer(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/payment_gateway_offer', JSON.stringify(credentials), {headers: headers});
}


addoffers(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/addoffers', JSON.stringify(credentials), {headers: headers});
}

addpromotion(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/addpromotion', JSON.stringify(credentials), {headers: headers});
}

updateoffers(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/updateoffers', JSON.stringify(credentials), {headers: headers});
}


updatepromotion(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/updatepromotion', JSON.stringify(credentials), {headers: headers});
}

deleteoffers(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/deleteoffers', JSON.stringify(credentials), {headers: headers});
}

deletepromotion(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/deletepromotion', JSON.stringify(credentials), {headers: headers});
}














addcategory(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/addcategory', JSON.stringify(credentials), {headers: headers});
}
addservice(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/addservice', JSON.stringify(credentials), {headers: headers});
}
updatecategory(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/updatecategory', JSON.stringify(credentials), {headers: headers});
}
updateservice(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/updateservice', JSON.stringify(credentials), {headers: headers});
}
randomPassword(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/randomPassword', JSON.stringify(credentials), {headers: headers});
}
updateuser(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/updateuser', JSON.stringify(credentials), {headers: headers});
}

findallappointmentbyclinic_id(clinic_id){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {clinic_id};
  return this.Http.post(this.url + 'api/findallappointmentbyclinic_id', JSON.stringify(credentials), {headers: headers});
}
findclinicById(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/findclinicById', JSON.stringify(credentials), {headers: headers});
}
finduseroffers(id_clinic){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {id_clinic};
  return this.Http.post(this.url + 'api/finduseroffers', JSON.stringify(credentials), {headers: headers});
}

deleteservice(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/deleteservice', JSON.stringify(credentials), {headers: headers});
}
deletecategory(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/deletecategory', JSON.stringify(credentials), {headers: headers});
}
addUser(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/addUser', JSON.stringify(credentials), {headers: headers});
}
deleteappointment(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/deleteappointment', JSON.stringify(credentials), {headers: headers});
}
noshowappointment(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/noshowappointment', JSON.stringify(credentials), {headers: headers});
}
confirmedappointment(item){
  let headers = new Headers();
 headers.append('Content-Type', 'application/json');
  let credentials = {item};
  return this.Http.post(this.url + 'api/confirmedappointment', JSON.stringify(credentials), {headers: headers});
}
  }
  